<template>
<div 
  class="sidebar"
  v-if="locationDatas.length != 0"
>
  <!-- <b-button id="menubtn" v-b-toggle.sidebar-backdrop  class="mr-3 border">
    <b-icon icon="list" aria-hidden="true" dark></b-icon>
  </b-button> -->
  <aside>
    <b-sidebar
      id="sidebar-backdrop"
      bg-variant="light"
      z-index="150"
      width="250px"
    >
      <b-col>
        <h4>
          {{ locationDatas[0].vcLocationName }}
        </h4>
      </b-col>
      <b-list-group  
        class="sidebar-link-area"
        v-for="(locationData,locationIndex) in locationDatas"
        :key="locationIndex"
      >
        <b-list-group-item  
          class="sidebar-link"  
          href="#" 
          @click="scrollProperty('#' + 'a' + locationData.cEstateID)"
        >
          {{ locationData.vcEstateName }}
        </b-list-group-item>
      </b-list-group>
    </b-sidebar>
  </aside>
</div>
</template>

<script>

export default {
  props:{
    locationDatas:[]
  },
  data() {
    return {
      
    }
  },
  methods: {
    scrollProperty(property) {
      this.$scrollTo(property,{
        offset:-50
      })
    }
  }
}
</script>

<style>
  .b-sidebar {
    display: none !important;
    width: 250px !important;
    height: 90% !important;
  }
/* SidebarをNavbarの下に配置 */
/* .b-sidebar,
.b-sidebar-backdrop {
  top: 56px;
  padding-bottom: 56px;
} */
/* .sidebar {
  height: 100%; 
  width: 300px; 
  max-width: 300px; 
  position: fixed; 
  overflow-x: hidden;  
  box-sizing: border-box;  
  padding-left: 40px;  
} */

.sidebar-link-area {
  padding-top: 20px; 
}

.sidebar-link:hover {
  color: black;
}

@media (min-width: 442px) {
  #menubtn,
  .b-sidebar-backdrop {
    display: none;
  }
  .b-sidebar {
    display: block !important;
  }
}

</style>
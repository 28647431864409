<template>
<div>
  <Header/>
  <b-overlay
    :show="showLoader"
    rounded="sm"
  >
    <SideMenu
      class="sidebar-area"
      style="padding-top:70px; "
      :locationDatas="locationDatas"
    />
    <b-container
      class="scafold-wrapper"
      style="padding-top:70px;  font-size:14px;"
    >
      <b-row>
        <b-col>
          <h3
            class="mb-0"
          >
            外観写真登録
          </h3>
        </b-col>
      </b-row>

      <b-row
        v-for="(locationData,locationIndex) in locationDatas"
        :key="locationIndex"
        :id="'a' + locationData.cEstateID"
      >

        <b-row
          class="property_info"
        >
          <b-col
            md="3"
          >
            <h6
              class="mb-0"
            >
              {{ locationData.vcEstateName }}
            </h6>
            <h6
              class="mb-0"
            >
              {{ locationData.vcAddress }}
            </h6>
            <div
              style="white-space: pre-wrap;"
            >
              備考:
              {{ locationData.txExterior }}
            </div >
          </b-col>
          <b-col>
            <b-row
              style="overflow-x: scroll; max-height:150px;"
            >
              <b-col
                v-for="(materialData,materialDataIndex) in locationData.material"
                :key="materialDataIndex"
              >
                <b-col
                  v-if="!materialData.vcMaterialID.includes('zip')"
                >
                  <b-link
                    :to="{name:'PDFPage', params : {
                      materialID: materialData.vcMaterialID,
                      materialName: materialData.materialName }}"
                    target="_blank"
                    style="text-decoration:none; "
                  >
                    {{ materialData.materialName }}
                    <pdf
                      style="max-width:200px;height:100px;"
                      :src="materialData.materialURL"
                    ></pdf>
                  </b-link>
                </b-col>
                <b-col
                  v-else-if="materialData.vcMaterialID.includes('zip')"
                >
                  <b-link
                    :href="materialData.materialURL"
                    download
                  >
                    {{ materialData.materialName }}(zip)
                  </b-link>
                </b-col>
              </b-col>
            </b-row>
          </b-col>
          <hr>
        </b-row>
        <!-- 土地戸建ての場合 -->
        <div
          v-if="houseFlag || landFlag"
        >
          <b-row
            class="mt-3 mb-3"
            v-if="locationData.previousPhoto.length != 0"
          >
            <b-col
              lg="2"
              md="12"

            >
              前回撮影
              <b-form-input
                readonly
                v-model="locationData.previousPhoto[0].vcProcessName"
                style="width:100%;"
              >
              </b-form-input>
              <b-form-group
                label="備考:"
              >
                <b-form-textarea
                  :value="locationData.previousPhoto[0].txNote"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-row
                class="flex-nowrap "
                style="overflow-x: scroll;"
              >
                <b-col
                  lg="3"
                  md="5"
                  v-for="(lastPhotoData,lastPhotoIndex) in locationData.previousMaterial"
                  :key="lastPhotoIndex"
                  class="me-5"
                >
                  <div
                    v-if="lastPhotoData.idMaterialID != null"
                  >
                    <b-img
                      :src="lastPhotoData.idMaterialID"
                      style="width:200px; height:150px;"
                    />
                    <b-row>
                      <b-col
                        class="col-md-1 w-auto"
                      >
                        撮影日:{{ lastPhotoData.dtShot }}
                      </b-col>
                    </b-row>
                  </div>
                  <div
                    v-else
                  >
                    <div
                      class="border d-flex align-items-center justify-content-center"
                      style="width:200px; height:150px;"
                    >
                    修正中です
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <!-- 前回写真がない場合 -->
          <b-row>
            <b-col
              style="z-index:10;"
              lg="2"
              md="3"
            >
              <!-- 戸建ての場合 -->
              <select
                v-if="locationData.tiTypeCd == 2"
                @change="changeProcess(
                  estateDatas[locationIndex][locationData.cEstateID].estateID
                )"
                class="form-select w-auto"
                v-model="estateDatas[locationIndex][locationData.cEstateID].process"
              >
                <option
                  value=""
                >
                  今回の工程を選択
                </option>
                <option
                  v-for="selectProcess in selectProcesses"
                  :key="selectProcess.id"
                  :value="selectProcess.id"
                  :hidden="selectProcess.id == 1 && estateDatas[locationIndex][locationData.cEstateID].oldProcessID == 0"
                >
                  {{ selectProcess.text }}
                </option>
              </select>
              <!-- 土地の場合 -->
              <select
                v-else-if="locationData.tiTypeCd == 1"
                class="form-select w-auto"
                v-model="estateDatas[locationIndex][locationData.cEstateID].process"
                @change="changeProcess(
                  estateDatas[locationIndex][locationData.cEstateID].estateID
                )"
              >
                <option
                  value=""
                >
                  今回の工程を選択
                </option>
                <option
                  v-for="landProcess in landProcesses"
                  :key="landProcess.id"
                  :value="landProcess.id"
                  :hidden="landProcess.id == 1 && estateDatas[locationIndex][locationData.cEstateID].oldProcessID == 0"
                >
                  {{ landProcess.text }}
                </option>
              </select>

              <b-row
                v-if="
                  estateDatas[locationIndex][locationData.cEstateID].selectProcess &&
                  !estateDatas[locationIndex][locationData.cEstateID].photoFormSortflag ||
                  estateDatas[locationIndex][locationData.cEstateID].noChangeProcess &&
                  !estateDatas[locationIndex][locationData.cEstateID].photoFormSortflag ||
                  estateDatas[locationIndex][locationData.cEstateID].constructionflag &&
                  !estateDatas[locationIndex][locationData.cEstateID].photoFormSortflag
                "
              >
                <b-col
                  class="mt-1"
                  v-if="estateDatas[locationIndex][locationData.cEstateID].process != 8"
                >
                  <label
                    for="nextShootDate"
                  >
                    次回撮影予定日:
                  </label>
                  <b-form-input
                    id="nextShootDate"
                    class="w-auto"
                    type="date"
                    v-model="estateDatas[locationIndex][locationData.cEstateID].nextShoot"
                  />
                </b-col>
                <b-form-group
                  label="備考："
                >
                  <b-form-textarea
                    v-model="estateDatas[locationIndex][locationData.cEstateID].comment"
                  />
                </b-form-group>
              </b-row>
              <div
                v-if="
                  estateDatas[locationIndex][locationData.cEstateID].selectProcess &&
                  !estateDatas[locationIndex][locationData.cEstateID].photoFormSortflag
                  "
                class="mt-1 "
              >
                <b-button
                  @click="sortPhoto(
                    estateDatas[locationIndex][locationData.cEstateID].estateID,
                    'exterior'
                  )"
                  variant="outline-success"
                >
                  写真並び替え
                </b-button>
              </div>
            </b-col>
            <!-- 写真登録フォーム -->
            <b-col
              lg="9"
              md="9"
              v-if="estateDatas[locationIndex][locationData.cEstateID].selectProcess "
            >
              <b-row>
                <b-col
                  class="text-center"
                >
                  <h4>
                  {{locationData.vcFixNumber}}
                  </h4>
                </b-col>
              </b-row>
              <carousel
                v-if="!estateDatas[locationIndex][locationData.cEstateID].photoFormSortflag"
                :per-page="mobile ? 1 : 3"
                :navigationEnabled="mobile ? false: true"
                :mouseDrag="false"
                :touchDrag="true"
                ref="carousel"
                navigation-prev-label="〈"
                navigation-next-label="〉"
              >
                <slide
                  class="p-2 "
                  v-for="(photoForm , formIndex) in photoForms[locationIndex][locationData.cEstateID]"
                  :key="formIndex"
                >

                  <b-row>
                    <b-col >
                      No.{{ formIndex + 1 }}
                    </b-col>
                  </b-row>

                  <div
                    class="image-input mx-auto"
                  >
                    <div
                      class="image-input__field"
                      :class="{'over': photoForm.isDragOver}"
                      @dragover.prevent="onDrag(
                        'over',
                        photoForm.formID,
                        photoForm.estateID,
                        photoForm.formType
                      )"
                      @dragleave="onDrag(
                        'leave',
                        photoForm.formID,
                        photoForm.estateID,
                        photoForm.formType
                      )"
                      @drop.stop="onDrop"
                      :name="[photoForm.formID,formIndex]"
                      :id="photoForm.estateID"
                    >
                      <input
                        v-if="!photoForm.image.src && photoForm.existingImage == ''"
                        :name="[photoForm.formID,formIndex]"
                        :id="photoForm.estateID"
                        :title="photoForm.formType"
                        type="file"
                        @change="onChange"
                      >
                      <!--イメージあり、既存なし-->
                      <div
                        v-if="photoForm.image.src && photoForm.existingImage == ''"
                      >
                        <div
                          style="text-align:right;"
                        >
                          <button
                            type="button"
                            class="btn-close "
                            aria-label="Close"
                            @click="deleteImage(
                              photoForm.formID,
                              photoForm.estateID,
                              photoForm.formType
                            )"
                          ></button>
                        </div>
                        <b-img
                          :src="photoForm.image.src"
                          class="img-thumbnail d-flex mx-auto"
                          style="max-height: 280px;"
                        />

                      </div>
                      <!--イメージあり、既存あり-->
                      <div
                        v-else-if="!photoForm.image.src && photoForm.existingImage != ''"
                      >
                        <div
                          style="text-align:right;"
                        >
                          <button
                            type="button"
                            class="btn-close "
                            aria-label="Close"
                            @click="deleteImage(
                              photoForm.formID,
                              photoForm.estateID,
                              photoForm.formType
                              )"
                          ></button>
                        </div>
                        <b-img
                          :src="photoForm.existingImage"
                          class="img-thumbnail d-flex mx-auto"
                          style="max-height: 280px;"
                        />
                      </div>
                      <p
                        v-else
                      >
                        画像をドラッグ＆ドロップ
                        <br>またはクリックでファイル選択
                      </p>
                    </div>
                  </div>

                  <b-row
                    v-if="formIndex == 2"
                  >
                  <!--
                    <b-col
                      v-for="(estate,estateIndex) in estateCheckBox"
                      :key="estateIndex"
                      class="text-nowrap form-check-inline"
                      md="auto"
                    >
                      <input
                        name="estateCheck"
                        v-model="copyPhotoFormChecks"
                        class="form-check-input"
                        type="checkbox"
                        :id="locationIndex + estate.estateID.estateName"
                        :value="estate.estateID"
                        :disabled="estate.checkFlag"
                      >
                      <label
                        class="form-check-label"
                        :for="locationIndex + estate.estateID.estateName"
                      >
                        {{ estate.estateID.estateName }}
                      </label>
                    </b-col>

                    <b-button
                      variant="success"
                      @click="copyPhotoModal(
                        photoForms[locationIndex][locationData.cEstateID][2],
                        photoForm.formType,
                        estateDatas[locationIndex][locationData.cEstateID]
                      )"
                    >
                      写真をコピー
                    </b-button>
                  -->
                    <!-- <b-row>
                      <b-col
                        v-for="(estateData,estateDataIndex) in estateDatas[locationIndex][locationData.cEstateID].copy"
                        :key="estateDataIndex"
                        md="auto"
                      >
                        {{estateData.estateName}}
                      </b-col>
                    </b-row> -->
                  </b-row>
                </slide>
              </carousel>
            </b-col>

            <!-- 土地戸建て造成中・古屋の場合表示 -->
            <b-col
              md="9"
              v-if="estateDatas[locationIndex][locationData.cEstateID].constructionflag "
            >
              <b-row>
                <b-col
                  class="text-center"
                >
                  <h4>
                  {{locationData.vcFixNumber}}
                  </h4>
                </b-col>
              </b-row>
              <carousel
                v-if="!estateDatas[locationIndex][locationData.cEstateID].photoFormSortflag"
                :per-page="3"
                :navigationEnabled="true"
                :mouseDrag="false"
              >
                <slide
                  class="p-2 "
                  v-for="(photoForm , formIndex) in constructionPhotoForms[locationIndex][locationData.cEstateID]"
                  :key="formIndex"
                >
                  <b-row>
                    <b-col>
                      No.{{ formIndex + 1 }}
                    </b-col>
                  </b-row>
                  <div
                    class="image-input mx-auto"
                  >
                    <div
                      class="image-input__field"
                      :class="{'over': photoForm.isDragOver}"
                      @dragover.prevent="onDrag(
                        'over',
                        photoForm.formID,
                        photoForm.estateID,
                        photoForm.formType
                      )"
                      @dragleave="onDrag(
                        'leave',
                        photoForm.formID,
                        photoForm.estateID,
                        photoForm.formType
                      )"
                      @drop.stop="onDrop"
                      :name="photoForm.formID"
                      :id="photoForm.estateID"
                      :title="photoForm.formType"
                    >
                      <input
                        v-if="!photoForm.image.src && photoForm.existingImage == ''"
                        :name="photoForm.formID"
                        :id="photoForm.estateID"
                        type="file"
                        :title="photoForm.formType"
                        @change="onChange"
                      >
                      <div
                        v-if="photoForm.image.src && photoForm.existingImage == ''"
                      >
                        <div
                          style="text-align:right;"
                        >
                          <button
                            type="button"
                            class="btn-close "
                            aria-label="Close"
                            @click="deleteImage(
                              photoForm.formID,
                              photoForm.estateID,
                              photoForm.formType
                            )"
                          ></button>
                        </div>
                        <b-img
                          :src="photoForm.image.src"
                          class="img-thumbnail d-flex mx-auto"
                          style="max-height: 280px;"
                        />
                      </div>
                      <div
                        v-else-if="!photoForm.image.src && photoForm.existingImage != ''"
                      >
                        <div
                          style="text-align:right;"
                        >
                          <button
                            type="button"
                            class="btn-close "
                            aria-label="Close"
                            @click="deleteImage(
                              photoForm.formID,
                              photoForm.estateID,
                              photoForm.formType
                              )"
                          ></button>
                        </div>
                        <b-img
                          :src="photoForm.existingImage"
                          class="img-thumbnail d-flex mx-auto"
                          style="max-height: 280px;"
                        />
                      </div>
                      <p
                        v-else
                      >
                        画像をドラッグ＆ドロップ
                        <br>またはクリックでファイル選択
                      </p>
                    </div>
                  </div>
                  <b-row>
                  <!--
                    <b-col
                      v-for="(estate,estateIndex) in estateCheckBox"
                      :key="estateIndex"
                      class="text-nowrap form-check-inline"
                    >
                      <input
                        name="estateCheck"
                        v-model="copyConstructionChecks"
                        class="form-check-input"
                        type="checkbox"
                        :id="locationIndex + estate.estateID.estateName"
                        :value="estate.estateID"
                        :disabled="estate.constructionCheckFlag"
                      >
                      <label
                        class="form-check-label"
                        :for="locationIndex + estate.estateID.estateName"
                      >
                        {{ estate.estateID.estateName }}
                      </label>
                    </b-col>
                    <b-button
                      variant="success"
                      @click="copyPhotoModal(
                        constructionPhotoForms[locationIndex][locationData.cEstateID][0],
                        photoForm.formType,
                        estateDatas[locationIndex][locationData.cEstateID]
                      )"
                    >
                      写真をコピー
                    </b-button>
                  -->
                  </b-row>
                </slide>
              </carousel>
            </b-col>
            <!-- フォーム追加ボタン -->
            <b-col
              v-if="!estateDatas[locationIndex][locationData.cEstateID].photoFormSortflag &&
              estateDatas[locationIndex][locationData.cEstateID].selectProcess "
              class="d-flex align-items-center justify-content-center "
            >
              <b-img
                :hidden="!estateDatas[locationIndex][locationData.cEstateID].addPhotoFormFlag"
                @click="makeAddPhotoForms(locationData.cEstateID)"
                src='/static/images/addbtn.png'
                class="img_link add_btn"
              />
            </b-col>

            <!-- 写真並べ替え時表示 -->
            <b-col
              v-if="estateDatas[locationIndex][locationData.cEstateID].photoFormSortflag"
              md="2"
            >
              <b-button
                @click="changeSort(
                  estateDatas[locationIndex][locationData.cEstateID].estateID,
                  photoForms,
                  photoForms[locationIndex][locationData.cEstateID][0].formType,
                  estateDatas
                )"
                variant="outline-success"
              >
                並び替える
              </b-button>
            </b-col>
            <b-row
              v-if="estateDatas[locationIndex][locationData.cEstateID].photoFormSortflag"
              style="overflow-x: scroll;"
            >
              <draggable
                class="d-flex flex-row ddSort"
                :animation="200"
                v-model="photoForms[locationIndex][locationData.cEstateID]"
              >
                <b-col
                  v-for="(photoForm , formIndex) in photoForms[locationIndex][locationData.cEstateID]"
                  :key="formIndex"
                  style="margin-right: 15px;"
                  md="3"
                >
                  <b-row>
                    <b-col>
                      No.{{ formIndex + 1 }}
                    </b-col>
                  </b-row>
                  <div
                    class="image-input mx-auto col-md-6"
                  >
                    <div
                      class="image-input__field"
                    >
                      <div
                        v-if="photoForm.image.src && photoForm.existingImage == ''"
                      >
                        <b-img
                          :src="photoForm.image.src"
                          class="img-thumbnail d-flex mx-auto"
                          style="max-height: 280px;"
                        />
                      </div>
                      <div
                        v-else-if="!photoForm.image.src && photoForm.existingImage != ''"
                      >
                        <b-img
                          :src="photoForm.existingImage"
                          class="img-thumbnail d-flex mx-auto"
                          style="max-height: 280px;"
                        />
                      </div>
                      <p
                        v-else
                      >
                        画像をドラッグ＆ドロップ
                        <br>またはクリックでファイル選択
                      </p>
                    </div>
                  </div>
                </b-col>
              </draggable>
            </b-row>
            <b-row
              mt="8"
            >
              <!--既に登録がある時-->
              <b-col
                v-if="registeredPhotoFlag"
                class="text-center"
              >
                <b-button
                  variant="primary"
                  style="width: 50%;"
                  @click="postPropertyPhotoModal(
                    locationData.vcEstateName,
                    estateDatas[locationIndex][locationData.cEstateID]
                  )"
                >
                  写真を更新する
                </b-button>
              </b-col>
              <!-- プロパティ(？)が空かつ工程が入っているとき -->
              <b-col
                v-else-if="!estateDatas[locationIndex][locationData.cEstateID].registProperty &&
                estateDatas[locationIndex][locationData.cEstateID].selectProcess"
                class="text-center"
              >
                <!-- マンションの時 -->
                <b-button
                  :disabled="!estateDatas[locationIndex][locationData.cEstateID].requiredFormFlag "
                  @click="postPropertyPhotoModal(
                    locationData.vcEstateName,
                    estateDatas[locationIndex][locationData.cEstateID]
                  )"
                  variant="primary"
                  style="width: 50%;"
                  :hidden="estateDatas[locationIndex][locationData.cEstateID].photoFormSortflag"
                >
                  写真を登録する
                </b-button>
              </b-col>
              <!-- プロパティが空かつ造成中 -->
              <b-col
                v-else-if="!estateDatas[locationIndex][locationData.cEstateID].registProperty &&
                estateDatas[locationIndex][locationData.cEstateID].constructionflag"
                class="text-center"
              >
                <!-- 土地戸建て造成中Flagがない時 -->
                <b-button
                  :disabled="!estateDatas[locationIndex][locationData.cEstateID].constructionRequiredFlag "
                  @click="postPropertyPhotoModal(
                    locationData.vcEstateName,
                    estateDatas[locationIndex][locationData.cEstateID]
                  )"
                  variant="primary"
                  style="width: 50%;"
                  :hidden="estateDatas[locationIndex][locationData.cEstateID].photoFormSortflag"
                >
                  写真を登録する
                </b-button>
              </b-col>
              <!-- 登録済みかつ工程が入っているとき -->
              <b-col
                v-else-if="registeredPhotoFlag &&
                estateDatas[locationIndex][locationData.cEstateID].selectProcess"
                class="text-center"
              >
                <!-- 変化なしの時 -->
                <b-button
                  :disabled="!estateDatas[locationIndex][locationData.cEstateID].noChangeProcess "
                  @click="postPropertyPhotoModal(
                    locationData.vcEstateName,
                    estateDatas[locationIndex][locationData.cEstateID]
                  )"
                  variant="primary"
                  style="width: 50%;"
                  :hidden="estateDatas[locationIndex][locationData.cEstateID].photoFormSortflag"
                >
                  写真を登録する
                </b-button>
              </b-col>
              <b-col
                v-else-if="!estateDatas[locationIndex][locationData.cEstateID].registProperty &&
                estateDatas[locationIndex][locationData.cEstateID].noChangeProcess"
                class="text-center"
              >
                <b-button
                  @click="postPropertyPhotoModal(
                    locationData.vcEstateName,
                    estateDatas[locationIndex][locationData.cEstateID]
                  )"
                  variant="primary"
                  style="width: 50%;"
                >
                  変化なしで登録
                </b-button>
              </b-col>

            </b-row>
          </b-row>
          <hr
            size="8"
          >
        </div>

        <!-- マンションの場合 -->
        <div
          v-else-if="mansionFlag"
        >
          <b-row
            class="mt-3 mb-3"
            v-if="locationData.previousPhoto.length != 0"
          >
            <b-col
              lg="2"
              md="12"

            >
              前回撮影
              <b-form-input
                readonly
                v-model="locationData.previousPhoto[0].vcProcessName"
                style="width:100%;"
              >
              </b-form-input>
              <b-form-group
                label="備考:"
              >
                <b-form-textarea
                  v-model="locationData.lastComment"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-row
                class="flex-nowrap "
                style="overflow-x: scroll;"
              >
                <b-col
                  lg="3"
                  md="5"
                  v-for="(lastPhotoData,lastPhotoIndex) in locationData.previousMaterial"
                  :key="lastPhotoIndex"
                  class="me-5"
                >
                  <div
                    v-if="lastPhotoData.idMaterialID != null"
                  >
                    <b-img
                      :src="lastPhotoData.idMaterialID"
                      style="width:200px; height:150px;"
                    />
                    <b-row>
                      <b-col
                        class="col-md-1 w-auto"
                      >
                        撮影日:{{ lastPhotoData.dtShot }}
                      </b-col>
                    </b-row>
                  </div>
                  <div
                    v-else
                  >
                    <div
                      class="border d-flex align-items-center justify-content-center"
                      style="width:200px; height:150px;"
                    >
                    修正中です
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row
            v-for="(photoForms,mansionFormIndex) in mansionPhotoForms[locationIndex][locationData.cEstateID]"
            :key="mansionFormIndex"
          >
            <b-col
              style="z-index:10;"
              lg="2"
              md="3"
            >
              <div
                v-if="!photoForms[0].photoFormSortflag"
                class="mt-1 "
              >
                <b-button
                  @click="sortPhoto(
                    estateDatas[locationIndex][locationData.cEstateID].estateID,
                    photoForms[0].formType,
                    photoForms[0].shotPlace,
                    mansionFormIndex
                  )"
                  variant="outline-success"
                >
                  写真並び替え
                </b-button>
              </div>
              <b-row
                class="mt-1"
                v-if="!photoForms[0].photoFormSortflag && photoForms[0].process == 1"
              >
                <select
                  class="form-select w-auto"
                  v-model="estateDatas[locationIndex][locationData.cEstateID].process"
                >
                  <option
                    value=""
                  >
                    今回の工程を選択
                  </option>
                  <option
                    v-for="mansionProcess in mansionProcesses"
                    :key="mansionProcess.id"
                    :value="mansionProcess.id"
                    :hidden="mansionProcess.id == 1 &&
                    estateDatas[locationIndex][locationData.cEstateID].oldProcessID == 0"
                  >
                    {{ mansionProcess.text }}
                  </option>
                </select>
                <b-col
                  class="mt-1"
                  v-if="estateDatas[locationIndex][locationData.cEstateID].process == 10"
                >
                  <label
                    for="nextShootDate"
                  >
                    次回撮影予定日:
                  </label>
                  <b-form-input
                    id="nextShootDate"
                    class="w-auto"
                    type="date"
                    v-model="estateDatas[locationIndex][locationData.cEstateID].nextShoot"
                  />
                </b-col>
              </b-row>
              <b-row
                v-if="!photoForms[0].photoFormSortflag && photoForms[0].process == 1"
              >
                <b-form-group
                  label="備考："
                >
                  <b-form-textarea
                    v-model="estateDatas[locationIndex][locationData.cEstateID].comment"
                  />
                </b-form-group>
              </b-row>

              <!-- <b-row
                class="mt-2"
                v-if="!photoForms[0].photoFormSortflag"
              >
                <b-col
                  v-for="(estate,estateIndex) in estateCheckBox"
                  :key="estateIndex"
                  class="text-nowrap form-check-inline"
                  md="auto"
                >
                  <input
                    name="estateCheck"
                    v-model="copyPhotoFormChecks"
                    class="form-check-input"
                    type="checkbox"
                    :id="locationIndex + estate.estateID.estateName"
                    :value="estate.estateID"
                    :disabled="estate.checkFlag"
                  >
                  <label
                    class="form-check-label"
                    :for="locationIndex + estate.estateID.estateName"
                  >
                    {{ estate.estateID.estateName }}
                  </label>
                </b-col>

                <b-button
                  variant="success"
                  @click="copyPhotoModal(
                    mansionPhotoForms[locationIndex][locationData.estateID],
                    photoForms[0].formType,
                    estateDatas[locationIndex][locationData.estateID],
                    photoForms[0].shotPlace
                  )"
                >
                  写真をコピー
                </b-button>
                <b-row>
                  <b-col
                    v-for="(estateData,estateDataIndex) in estateDatas[locationIndex][locationData.estateID].copy"
                    :key="estateDataIndex"
                    md="auto"
                  >
                    {{ estateData.estateName }}
                  </b-col>
                </b-row>
              </b-row> -->
            </b-col>
            <!-- 写真登録フォーム -->
            <b-col
              lg="9"
              md="9"
            >
              <carousel
                v-if="!photoForms[0].photoFormSortflag"
                :per-page="mobile ? 1 : 3"
                :navigationEnabled="mobile ? false: true"
                :mouseDrag="false"
                :touchDrag="true"
                ref="carousel"
                navigation-prev-label="〈"
                navigation-next-label="〉"
              >
                <slide
                  class="p-2 "
                  v-for="(photoForm , formIndex) in photoForms"
                  :key="formIndex"
                >
                  <b-row>
                    <b-col>
                      {{ photoForm.shotPlace +  (formIndex+ 1) }}
                    </b-col>
                  </b-row>
                  <div
                    class="image-input mx-auto"
                  >
                    <div
                      class="image-input__field"
                      :class="{'over': photoForm.isDragOver}"
                      @dragover.prevent="onDrag(
                        'over',
                        photoForm.formID,
                        photoForm.estateID,
                        photoForm.formType
                      )"
                      @dragleave="onDrag(
                        'leave',
                        photoForm.formID,
                        photoForm.estateID,
                        photoForm.formType
                      )"
                      @drop.stop="onDrop"
                      :name="[photoForm.formID,formIndex]"
                      :id="photoForm.estateID"
                    >
                      <input
                        v-if="!photoForm.image.src && photoForm.existingImage == ''"
                        :name="[photoForm.formID,formIndex]"
                        :id="photoForm.estateID"
                        :title="photoForm.formType"
                        type="file"
                        @change="onChange"
                      >
                      <div
                        v-if="photoForm.image.src && photoForm.existingImage == ''"
                      >
                        <div
                          style="text-align:right;"
                        >
                          <button
                            type="button"
                            class="btn-close "
                            aria-label="Close"
                            @click="deleteImage(
                              photoForm.formID,
                              photoForm.estateID,
                              photoForm.formType,
                              photoForm.shotPlace
                            )"
                          ></button>
                        </div>
                        <b-img
                          :src="photoForm.image.src"
                          class="img-thumbnail d-flex mx-auto"
                          style="max-height: 280px;"
                        />
                      </div>

                      <div
                        v-else-if="!photoForm.image.src && photoForm.existingImage != ''"
                      >
                        <div
                          style="text-align:right;"
                        >
                          <button
                            type="button"
                            class="btn-close "
                            aria-label="Close"
                            @click="deleteImage(
                              photoForm.formID,
                              photoForm.estateID,
                              photoForm.formType,
                              photoForm.shotPlace
                              )"
                          ></button>
                        </div>
                        <b-img
                          :src="photoForm.existingImage"
                          class="img-thumbnail d-flex mx-auto"
                          style="max-height: 280px;"
                        />

                      </div>
                      <p
                        v-else
                      >
                        画像をドラッグ＆ドロップ
                        <br>またはクリックでファイル選択
                      </p>
                    </div>
                  </div>
                </slide>
              </carousel>
            </b-col>
            <!-- フォーム追加ボタン -->
            <b-col
              v-if="!photoForms[0].photoFormSortflag"
              class="d-flex align-items-center justify-content-center "
            >
              <span
                v-for="(mansionPlace,mansionPlaceIndex) in estateDatas[locationIndex][locationData.cEstateID].mansion"
                :key="mansionPlaceIndex"
                >
                <b-img
                  v-if="photoForms[0].shotPlace == mansionPlace.shotPlace"
                  :hidden="!mansionPlace.requiredFormFlag"
                  @click="makeAddMansionPhotoForms(
                    locationData.cEstateID,
                    mansionPlace.shotPlace,
                    mansionPlace.processID
                  )"
                  src='/static/images/addbtn.png'
                  class="img_link add_btn"
                />
              </span>
            </b-col>
            <!-- 写真並べ替え時表示 -->
            <b-col
              v-if="photoForms[0].photoFormSortflag"
              md="2"
            >
              <b-button
                @click="changeSort(
                  estateDatas[locationIndex][locationData.cEstateID].estateID,
                  photoForms,
                  photoForms[0].formType,
                  estateDatas[locationIndex][locationData.cEstateID],
                  photoForms[0].shotPlace
                )"
                variant="outline-success"
              >
                並び替える
              </b-button>
            </b-col>
            <b-row
              v-if="photoForms[0].photoFormSortflag"
              style="overflow-x: scroll;"
            >
              <draggable
                class="d-flex flex-row ddSort"
                :animation="200"
                v-model="mansionPhotoForms[locationIndex][locationData.cEstateID][count]"
              >
                <b-col
                  v-for="(photoForm , formIndex) in photoForms"
                  :key="formIndex"
                  style="margin-right: 15px;"
                  md="3"
                >
                  <b-row>
                    <b-col>
                      {{ photoForm.shotPlace + (formIndex + 1)}}
                    </b-col>
                  </b-row>
                  <div
                    class="image-input mx-auto col-md-6"
                  >
                    <div
                      class="image-input__field"
                    >
                      <div
                        v-if="photoForm.image.src"
                      >
                        <b-img
                          :src="photoForm.image.src"
                          class="img-thumbnail d-flex mx-auto"
                          style="max-height: 280px;"
                        />
                      </div>
                      <div
                        v-else-if="!photoForm.image.src && photoForm.existingImage != ''"
                      >
                        <b-img
                          :src="photoForm.existingImage"
                          class="img-thumbnail d-flex mx-auto"
                          style="max-height: 280px;"
                        />
                      </div>
                      <p
                        v-else
                      >
                        画像をドラッグ＆ドロップ
                        <br>またはクリックでファイル選択
                      </p>
                    </div>
                  </div>
                </b-col>
              </draggable>
            </b-row>
          </b-row>
          <b-row
            mt="8"
          >
            <b-col
              v-if="!estateDatas[locationIndex][locationData.cEstateID].registProperty"
              class="text-center"
            >
              <b-button
                :disabled="!estateDatas[locationIndex][locationData.cEstateID].requiredFormFlag "
                @click="postPropertyPhotoModal(
                  locationData.vcEstateName,
                  estateDatas[locationIndex][locationData.cEstateID]
                )"
                variant="primary"
                style="width: 50%;"
                :hidden="estateDatas[locationIndex][locationData.cEstateID].photoFormSortflag"
              >
                写真を登録する
              </b-button>
            </b-col>
            <!-- 登録済みかつ工程が入っているとき -->
            <b-col
            v-else-if="registeredPhotoFlag &&
            estateDatas[locationIndex][locationData.cEstateID].selectProcess"
            class="text-center"
            >
            <!-- 変化なしの時 -->
            <b-button
              :disabled="!estateDatas[locationIndex][locationData.cEstateID].noChangeProcess "
              @click="postPropertyPhotoModal(
                locationData.vcEstateName,
                estateDatas[locationIndex][locationData.cEstateID]
              )"
              variant="primary"
              style="width: 50%;"
              :hidden="estateDatas[locationIndex][locationData.cEstateID].photoFormSortflag"
            >
              写真を登録する
            </b-button>
            </b-col>
            <b-col
              v-else
              class="text-center"
            >
              <b-button
                :disabled="estateDatas[locationIndex][locationData.cEstateID].photoFormSortflag"
                variant="success"
                style="width: 50%;"
              >
                更新する
              </b-button>
            </b-col>
          </b-row>
          <hr>

        </div>
      </b-row>

    </b-container>
  </b-overlay>
</div>
</template>

<script>
import Header from '../../components/Header.vue'
import SideMenu from '../../components/PropertySideMenu.vue'
import moment from 'moment'
import { Apimixin } from '../../mixins/api'
import { UploadImage } from '../../mixins/propertyuploadimage'
import aws from 'aws-sdk'
import draggable from 'vuedraggable'

export default {
  title:'外観写真登録',
  mixins:[Apimixin,UploadImage],
  components: {
    Header,
    draggable,
    SideMenu
  },
  data() {
    return {
      process:[],
      selectProcesses:[
        { id:1,text:'変化なし',value:'変化なし' },
        { id:2,text:'造成中・古屋',value:'造成中・古屋' },
        { id:3,text:'更地',value:'更地' },
        { id:4,text:'基礎コンクリ',value:'基礎' },
        { id:5,text:'上棟',value:'上棟' },
        { id:6,text:'シート',value:'シート' },
        { id:7,text:'外構',value:'外構' },
        { id:10,text:'完成(再撮影)',value:'完成(再撮影)' },
        { id:8,text:'完成',value:'完成' },
      ],
      landProcesses:[
        {id:1,text:'変化なし',value:'変化なし'},
        {id:2,text:'造成中・古屋',value:'造成中・古屋'},
        {id:9,text:'更地(再撮影)',value:'更地(再撮影)'},
        {id:3,text:'更地',value:'更地'},
      ],
      mansionProcesses:[
        {id:10,text:'完成(再撮影)',value:'完成(再撮影)'},
        {id:8,text:'完成',value:'完成'},
      ],
      selectLandProcesses:[
        {id:1,text:'造成中・古屋',value:'造成中・古屋'},
        {id:2,text:'更地',value:'更地'},
      ],
      mansionShootPlaces:[
        {id:1,text:'外観',value:'外観',shotCount:3},
        {id:2,text:'エントランス',value:'エントランス',shotCount:2},
      ],
      houseRenameMaster:[
        { processID:2,orderNo:1,processNo:66 },
        { processID:3,orderNo:1,processNo:67 },
        { processID:3,orderNo:2,processNo:68 },
        { processID:3,orderNo:3,processNo:69 },
        { processID:4,orderNo:1,processNo:71 },
        { processID:4,orderNo:2,processNo:72 },
        { processID:4,orderNo:3,processNo:73 },
        { processID:5,orderNo:1,processNo:74 },
        { processID:5,orderNo:2,processNo:75 },
        { processID:5,orderNo:3,processNo:76 },
        { processID:6,orderNo:1,processNo:77 },
        { processID:6,orderNo:2,processNo:78 },
        { processID:6,orderNo:3,processNo:79 },
        { processID:7,orderNo:1,processNo:80 },
        { processID:7,orderNo:2,processNo:81 },
        { processID:7,orderNo:3,processNo:82 },
        { processID:8,orderNo:1,processNo:83 },
        { processID:8,orderNo:2,processNo:84 },
        { processID:8,orderNo:3,processNo:85 },
      ],
      landRenameMaster:[
        { processID:2,orderNo:1,processNo:66 },
        { processID:3,orderNo:1,processNo:67 },
        { processID:3,orderNo:2,processNo:68 },
        { processID:3,orderNo:3,processNo:69 },
      ],
      mansionRenameMaster:[
        { processID:1,orderNo:1,processNo:67 },
        { processID:1,orderNo:2,processNo:68 },
        { processID:1,orderNo:3,processNo:81 },
        { processID:2,orderNo:1,processNo:69 },
        { processID:2,orderNo:2,processNo:82 },
      ],
      drag:false,
      notExifData:false,
      photoForms:[],
      mansionPhotoForms:[],
      estateDatas:[],
      constructionPhotoForms:[],
      copyConstructionChecks:[],
      copyPhotoFormChecks:[],
      estateIDs:[],
      message:{
        notDate:''
      },
      estateCheckBox:[],
      shootType:'',
      count:'',
      mansionFlag:false,
      houseFlag:false,
      landFlag:false,
      estateKindType:'',
      locationDatas:[],
      registeredProcessID:'',
      registeredPhotoFlag:false,
      registeredPhoto:[],
      estateID:'',
      shootingProgressID:'',
      userID:'',
      toDay:'',
      sendPhotoDatas:[],
      shootTypeName:'',
      shootingType:'',
      requiredNumber:3,
      mansionExteriorRequiredNumber:2,
      mansionEntrenceRequiredNumber:1,
      isButtonDisabled: false
    }
  },
  created() {
    this.showLoader = true
    this.shootType = this.$route.params.typeID
    this.registeredProcessID = this.$route.params.processID
    this.shootingProgressID = this.$route.params.shootingProgressID
    this.userID = sessionStorage.getItem('userId')
    this.toDay = moment().format('YYYY-MM-DD');
    this.shootingType = this.$route.params.shootingType
    //物件のタイプを取得
    this.getEstateTypeName()
    //物件詳細情報取得
    this.getEstateDetail(
      this.$route.params.locationID,
      this.$route.params.shootingType,
      this.$route.params.shootingDate,
      null,
      this.$route.params.progressID,
      this.$route.params.shootingProgressID,
    )
    this.s3 = new aws.S3({
      accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.VUE_APP_AWS_S3_REGION
    });
    this.s3Bucket = process.env.VUE_APP_AWS_S3_BUCKET
  },
  methods:{
    //物件のタイプ名を取得
    getEstateTypeName() {
      console.log("getEstateTypeName: " + this.shootTypeName)
      switch(this.shootType) {
        case 1:
          this.shootTypeName = 'property'
        break;
        case 2:
          this.shootTypeName = 'property'
        break;
        case 3:
          this.shootTypeName = 'mansion'
      }
    },
    //建築工程が選択されたとき
    changeProcess(estateID) {
      for(let estateData of this.estateDatas) {
        if(Object.keys(estateData) == estateID) {
          if(estateData[estateID].process != '') {
            if(estateData[estateID].process == 1) {
              //変化なし
              estateData[estateID].nextShoot = moment().add(7,'days').format('YYYY-MM-DD')
              //工程変更なし
              estateData[estateID].noChangeProcess = true
              //工程変更
              estateData[estateID].selectProcess = false
              //造成中
              estateData[estateID].constructionflag = false
              return
            } else if(estateData[estateID].process == 2) {
              //造成中・古屋
              estateData[estateID].nextShoot = moment().add(7,'days').format('YYYY-MM-DD')
              estateData[estateID].selectProcess = false
              estateData[estateID].noChangeProcess = false
              estateData[estateID].constructionflag = true
            } else if(estateData[estateID].process == 3 && estateData[estateID].typeCode == 1) {
              //更地・土地
              estateData[estateID].nextShoot = moment().add(6,'M').format('YYYY-MM-DD')
              estateData[estateID].selectProcess = true
              estateData[estateID].noChangeProcess = false
              estateData[estateID].constructionflag = false
            } else {
              //それ以外
              estateData[estateID].nextShoot = moment().add(7,'days').format('YYYY-MM-DD')
              estateData[estateID].selectProcess = true
              estateData[estateID].noChangeProcess = false
              estateData[estateID].constructionflag = false
            }
          } else {  // 工程が空
            console.log("工程が空")
            estateData[estateID].noChangeProcess = false
            estateData[estateID].selectProcess = false
            estateData[estateID].constructionflag = false
          }
        }
      }
    },
    //既存の写真があるかチェック
    getRegisteredPhoto() {
      console.log("getRegisteredPhoto")
      console.log("estateDatas: " + this.estateDatas)
      //土地戸建ての場合
      if(this.landFlag || this.houseFlag) {
        for(let i = 0;i < this.estateDatas.length;i++) {
          this.estateDatas[i][this.estateIDs[i]].process = this.registeredProcessID
          this.estateDatas[i][this.estateIDs[i]].comment = this.locationDatas[0].photo[0].txNote
        }
        let orderID = ''
        for(let existingPhotoData of this.registeredPhoto) {
          orderID = (existingPhotoData.inOrder - 1)
          //造成中の場合
          switch(this.registeredProcessID) {
            case 2:
              for(let photoForm of this.constructionPhotoForms) {
                photoForm[existingPhotoData.cEstateID][0].existingImage = existingPhotoData.vcStorage
                photoForm[existingPhotoData.cEstateID][0].photoID = existingPhotoData.inPhotoID
                photoForm[existingPhotoData.cEstateID][0].orderNo = existingPhotoData.inOrder
                photoForm[existingPhotoData.cEstateID][0].uploadFlag = true
                photoForm[existingPhotoData.cEstateID][0].shootDate = existingPhotoData.dtPhotoDate
                this.checkForm(
                  existingPhotoData.cEstateID,
                  photoForm[existingPhotoData.cEstateID][0].formType
                )
              }
            break;
            //造成中以外の工程の場合
            default:
              for(let photoForm of this.photoForms) {
                if(typeof photoForm[existingPhotoData.cEstateID][orderID] !== "undefined") {
                  photoForm[existingPhotoData.cEstateID][orderID].existingImage =  existingPhotoData.vcStorage
                  photoForm[existingPhotoData.cEstateID][orderID].photoID = existingPhotoData.inPhotoID
                  photoForm[existingPhotoData.cEstateID][orderID].orderNo = existingPhotoData.inOrder
                  photoForm[existingPhotoData.cEstateID][orderID].uploadFlag = true
                  photoForm[existingPhotoData.cEstateID][orderID].shootDate = existingPhotoData.dtPhotoDate
                  this.checkForm(
                    existingPhotoData.cEstateID,
                    photoForm[existingPhotoData.cEstateID][0].formType
                  )
                } else {
                  photoForm[existingPhotoData.cEstateID].push(
                    {
                      estateName:photoForm[existingPhotoData.cEstateID][0].estateName,
                      estateID:photoForm[existingPhotoData.cEstateID][0].estateID,
                      formID:(orderID + 1)  ,
                      shootDate: '',
                      nextShoot: '',
                      comment: '',
                      image: '',
                      orderNo:'',
                      existingImage:'',
                      uploadFile:[],
                      isDragOver:false,
                      photoID:'',
                      photoFormSortflag:false,
                      uploadFlag:false,
                      processNumber:'',
                      formType:photoForm[existingPhotoData.cEstateID][0].formType
                    }
                  )
                  this.getRegisteredPhoto()
                  return
                }
              }
          }
        }
      //マンションの場合
      } else if(this.mansionFlag) {
        let orderID = ''
        for(let i = 0;i < this.estateDatas.length;i++) {
          this.estateDatas[i][this.estateIDs[i]].process = this.registeredProcessID
          this.estateDatas[i][this.estateIDs[i]].comment = this.locationDatas[0].photo[0].txNote
        }
        for(let existingPhotoData of this.registeredPhoto) {
          orderID = (existingPhotoData.inOrder - 1)
          for(let mansionPhotoForm of this.mansionPhotoForms) {
            for(let photoForm of mansionPhotoForm[this.estateID]) {
              if(photoForm[0].process == existingPhotoData.inMansionShootingType) {
                if(typeof photoForm[orderID] !== "undefined") {
                  photoForm[orderID].existingImage = existingPhotoData.vcStorage
                  photoForm[orderID].photoID = existingPhotoData.inPhotoID
                  photoForm[orderID].orderNo = existingPhotoData.inOrder
                  photoForm[orderID].uploadFlag = true
                  photoForm[orderID].shootDate = existingPhotoData.dtPhotoDate
                  this.checkForm(
                    photoForm[0].estateID,
                    photoForm[0].formType,
                    photoForm[0].shotPlace,
                  )
                }
                else {
                  photoForm.push(
                    {
                      estateName:photoForm[0].estateName,
                      estateID:photoForm[0].estateID,
                      formID:photoForm[0].shotPlace + photoForm.length ,
                      shootDate: '',
                      nextShoot: '',
                      comment: '',
                      image: '',
                      orderNo:'',
                      existingImage:'',
                      uploadFile:[],
                      isDragOver:false,
                      photoID:'',
                      photoFormSortflag:false,
                      uploadFlag:false,
                      processNumber:'',
                      process:photoForm[0].process,
                      shotPlace:photoForm[0].shotPlace ,
                      formType:photoForm[0].formType
                    }
                  )
                  this.getRegisteredPhoto()
                  return
                }
              }
            }
          }
        }
      }
    },
    //土地・戸建てのフォーム作成
    createHouseForms() {
      for(let locationData of this.locationDatas) {
        this.estateIDs.push(locationData.cEstateID)
        this.makePhotoForms(
          locationData.cEstateID,
          locationData.vcEstateName
        )
        this.makeEstateData(
          locationData.cEstateID,
          locationData.vcEstateName,
          locationData.inPropertyMasterID,
          locationData.inProcessID,
          locationData.inShootingPrpgressID,
          locationData.cLocationID,
          locationData.vcFixNumber,
          locationData.tiTypeCd
        )
        this.makeConstructionPhotoForms(
          locationData.cEstateID,
          locationData.vcEstateName
        )
        this.makeEstateCheckBox(
          locationData.cEstateID,
          locationData.vcEstateName
        )
      }

    },
    //マンション用フォーム作成
    createMansionForms() {
      for(let locationData of this.locationDatas) {
        this.estateIDs.push(locationData.cEstateID)
        this.makeEstateData(
          locationData.cEstateID,
          locationData.vcEstateName,
          locationData.inPropertyMasterID,
          locationData.inProcessID,
          locationData.inShootingPrpgressID,
          locationData.cLocationID,
          locationData.vcFixNumber,
          locationData.tiTypeCd
        )
        this.makeMansionPhotoForms(
          locationData.cEstateID,
          locationData.vcEstateName
        )
        this.makeEstateCheckBox(
          locationData.cEstateID,
          locationData.vcEstateName
        )
      }
    },
    //物件情報フォーム
    makeEstateData(
      estateID,
      estateName,
      propertyMasterID,
      oldProcessID,
      shootingProgressID,
      locationID,
      fixNumber,
      typeCode
      ) {
      let nextShootDate = moment().add(7,'days').format('YYYY-MM-DD')
      this.estateDatas.push({[estateID]:
        {
          estateID:estateID,
          estateName:estateName,
          locationID:locationID,
          fixNumber:fixNumber,
          process:'',
          selectProcess:false,
          noChangeProcess:false,
          requiredFormFlag:false,
          photoFormSortflag:false,
          registProperty:false,
          constructionflag:false,
          constructionRequiredFlag:false,
          addPhotoFormFlag:false,
          nextShoot:nextShootDate,
          comment:'',
          copy:[],
          propertyMasterID:propertyMasterID,
          oldProcessID:oldProcessID,
          shootingProgressID:shootingProgressID,
          typeCode:typeCode,
          mansion:[
            {
              processID:1,
              shotPlace:'外観',
              requiredFormFlag:false,
              photoFormSortflag:false
            },
            {
              processID:2,
              shotPlace:'エントランス',
              requiredFormFlag:false,
              photoFormSortflag:false
            },
          ]
        }
      })
    },
    //マンション用写真投稿フォーム作成
    makeMansionPhotoForms(estateID,estateName) {
      for(let photoForm of this.mansionPhotoForms) {
        if(Object.keys(photoForm)[0] == estateID) {
          for(let i = 0;i < this.mansionShootPlaces.length;i++) {
            if(this.mansionShootPlaces[i].text == '外観') {
              photoForm[estateID].push([
                {
                  estateName:estateName,
                  estateID:estateID,
                  formID:this.mansionShootPlaces[i].text + 0,
                  shotPlace:this.mansionShootPlaces[i].text,
                  othersName:'',
                  process:this.mansionShootPlaces[i].id,
                  shootDate: '',
                  nextShoot: '',
                  image: '',
                  orderNo:'',
                  existingImage:'',
                  uploadFile:[],
                  isDragOver:false,
                  uploadFlag:false,
                  photoFormSortflag:false,
                  photoID:'',
                  processNumber:'',
                  formType:'mansion',
                  uploadFileName:''
                },
                {
                  estateName:estateName,
                  estateID:estateID,
                  formID:this.mansionShootPlaces[i].text + 1,
                  shotPlace:this.mansionShootPlaces[i].text,
                  othersName:'',
                  process:this.mansionShootPlaces[i].id,
                  shootDate: '',
                  nextShoot: '',
                  image: '',
                  orderNo:'',
                  existingImage:'',
                  uploadFile:[],
                  isDragOver:false,
                  uploadFlag:false,
                  photoFormSortflag:false,
                  photoID:'',
                  processNumber:'',
                  formType:'mansion',
                  uploadFileName:''
                }
              ])
            } else if(this.mansionShootPlaces[i].text == 'エントランス') {
              photoForm[estateID].push([
                {
                  estateName:estateName,
                  estateID:estateID,
                  formID:this.mansionShootPlaces[i].text + 0,
                  shotPlace:this.mansionShootPlaces[i].text,
                  othersName:'',
                  process:this.mansionShootPlaces[i].id,
                  shootDate: '',
                  nextShoot: '',
                  image: '',
                  orderNo:'',
                  existingImage:'',
                  uploadFile:[],
                  isDragOver:false,
                  uploadFlag:false,
                  photoFormSortflag:false,
                  photoID:'',
                  processNumber:'',
                  formType:'mansion',
                  uploadFileName:''
                }
              ])
            }
          }
        }
      }
    },
    //マンション写真投稿フォームを追加
    makeAddMansionPhotoForms(estateID,shotPlace,processID) {
      for(let i = 0;i < this.mansionPhotoForms.length;i++) {
        if(Object.keys(this.mansionPhotoForms[i])[0] == estateID) {
          for(let mansionPhotoForms of this.mansionPhotoForms[i][estateID]) {
            for(let mansionPhotoForm of mansionPhotoForms) {
              if(mansionPhotoForm.shotPlace == shotPlace) {
                mansionPhotoForms.push({
                  estateID:estateID,
                  formID:shotPlace + mansionPhotoForms.length ,
                  shotPlace:shotPlace,
                  process:processID,
                  shootDate: '',
                  nextShoot: '',
                  image: '',
                  uploadFile:[],
                  isDragOver:false,
                  uploadFlag:false,
                  formType:'mansion',
                  uploadFileName:'',
                  photoFormSortflag:false,
                  existingImage:'',
                  photoID:'',
                })
              this.$refs.carousel[0].goToLastSlide()
              return
              }
            }
          }

        }
      }
    },
    //写真投稿フォームを生成
    makePhotoForms(estateID,estateName) {
      this.photoForms.push({[estateID]:[
        {
          estateName:estateName,
          estateID:estateID,
          formID:0,
          shootDate: '',
          nextShoot: '',
          image: '',
          uploadFile:[],
          isDragOver:false,
          uploadFlag:false,
          orderNo:'',
          formType:'exterior',
          uploadFileName:'',
          existingImage:'',
          photoID:''
        },
        {
          estateName:estateName,
          estateID:estateID,
          formID:1,
          shootDate: '',
          nextShoot: '',
          image: '',
          uploadFile:[],
          isDragOver:false,
          uploadFlag:false,
          orderNo:'',
          formType:'exterior',
          uploadFileName:'',
          existingImage:'',
          photoID:''
        },
        {
          estateName:estateName,
          estateID:estateID,
          formID:2,
          shootDate: '',
          nextShoot: '',
          image: '',
          uploadFile:[],
          isDragOver:false,
          uploadFlag:false,
          orderNo:'',
          formType:'exterior',
          uploadFileName:'',
          existingImage:'',
          photoID:''
        }
      ]})
    },
    //写真投稿フォームを追加
    makeAddPhotoForms(estateID) {
      for(let i = 0;i < this.photoForms.length;i++) {
        if(Object.keys(this.photoForms[i])[0] == estateID) {
          this.photoForms[i][estateID].push({
            estateID:estateID,
            formID:this.photoForms[i][estateID].length ,
            shootDate: '',
            nextShoot: '',
            image: '',
            uploadFile:[],
            isDragOver:false,
            formType:'exterior',
            uploadFileName:'',
            existingImage:'',
            photoID:''
          })
          this.$refs.carousel[0].goToLastSlide()
          return
        }
      }
    },
    //造成中・古屋のフォーム作成
    makeConstructionPhotoForms(estateID,estateName) {
      this.constructionPhotoForms.push({[estateID]:[
        {
          estateName:estateName,
          estateID:estateID,
          formID:0,
          shootDate: '',
          nextShoot: '',
          image: '',
          uploadFile:[],
          isDragOver:false,
          copyPhotoFlag:false,
          uploadFlag:false,
          formType:'construction',
          uploadFileName:'',
          existingImage:'',
          photoID:''
        }
      ]})
    },
    //並べ替え開始
    sortPhoto(estateID,formType,shotPlace,index) {
      switch(formType) {
        //土地・戸建ての場合
        case 'exterior':
          for(let estateData of this.estateDatas) {
            if(Object.keys(estateData)[0] == estateID) {
              estateData[estateID].photoFormSortflag = true
            }
          }
        break;
        //マンションの場合
        case 'mansion':
          this.count = ''
          this.count = index
          for(let mansionPhotoForm of this.mansionPhotoForms) {
            if(Object.keys(mansionPhotoForm)[0] == estateID) {
              for(let t = 0;t < mansionPhotoForm[estateID].length;t++) {
                if(mansionPhotoForm[estateID][t][0].shotPlace == shotPlace) {
                  mansionPhotoForm[estateID][t][0].photoFormSortflag = true
                }
              }
            }
          }
        break;
      }
    },

    //並べ替え終了
    changeSort(estateID,copyData,formType,estate,shotPlace) {
      switch (formType) {
        //土地・戸建ての場合
        case 'exterior':
          for(let estateData of this.estateDatas) {
            if(Object.keys(estateData)[0] == estateID) {
              estateData[estateID].photoFormSortflag = false
              this.copyPhoto(copyData,formType,estate)
            }
          }
        break;
        //マンションの場合
        case 'mansion':
          for(let mansionPhotoForm of this.mansionPhotoForms) {
            if(Object.keys(mansionPhotoForm)[0] == estateID) {
              for(let t = 0;t < mansionPhotoForm[estateID].length;t++) {
                if(mansionPhotoForm[estateID][t][0].shotPlace == shotPlace) {
                  mansionPhotoForm[estateID][t][0].photoFormSortflag = false
                }
              }
            }
          }
        break;
      }
    },
    //写真コピー用のチェックボックス
    makeEstateCheckBox(estateID,estateName) {
      this.estateCheckBox.push(
        {
          estateID:{
            estateID,
            estateName:estateName
            },
          checkFlag:false,
          constructionCheckFlag:false,
          copy:[]
        }
      )
    },
    //写真データのコピーモーダル
    copyPhotoModal(copyDatas,formType,estateData,shotPlace) {
      this.$swal({
        title: '写真のコピー',
        html: `${estateData.estateName}の写真をコピーしますか？`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '確定',
        cancelButtonText:'キャンセル'
      })
      .then(result => {
        if(result.isConfirmed) {
          this.copyPhoto(
            copyDatas,
            formType,
            estateData,
            shotPlace
          )
        }
      })
    },
    //写真データのコピー
    copyPhoto(copyDatas,formType,estateData,shotPlace) {
      switch(formType) {
        //土地・戸建ての場合
        case 'exterior':
          for(let i = 0;i < this.copyPhotoFormChecks.length;i++) {
            for(let t = 0;t < this.photoForms[i][this.estateIDs[i]].length;t++) {
              if(this.photoForms[i][this.estateIDs[i]][t].estateID == this.copyPhotoFormChecks[i].estateID &&
                !this.estateCheckBox[i].checkFlag) {
                this.estateDatas[i][this.photoForms[i][this.estateIDs[i]][t].estateID].copy = []
                this.photoForms[i][this.estateIDs[i]][t].uploadFile = copyDatas[i][this.estateIDs[i]][t].uploadFile
                this.photoForms[i][this.estateIDs[i]][t].image = copyDatas[i][this.estateIDs[i]][t].image
                this.photoForms[i][this.estateIDs[i]][t].shootDate = copyDatas[i][this.estateIDs[i]][t].shootDate
                this.photoForms[i][this.estateIDs[i]][t].nextShoot = copyDatas[i][this.estateIDs[i]][t].nextShoot
                this.photoForms[i][this.estateIDs[i]][t].uploadFlag = true
                this.estateDatas[i][this.photoForms[i][this.estateIDs[i]][t].estateID].process = estateData[i][this.estateIDs[i]].process
                this.estateDatas[i][this.photoForms[i][this.estateIDs[i]][t].estateID].selectProcess = true
                this.estateDatas[i][this.photoForms[i][this.estateIDs[i]][t].estateID].copy = (this.copyPhotoFormChecks[i])
                this.checkForm(
                  this.photoForms[i][this.estateIDs[i]][t].estateID,
                  this.photoForms[i][this.estateIDs[i]][t].formType
                )
                this.changeProcess(this.photoForms[i][this.estateIDs[i]][t].estateID )
              }
            }
          }
          this.copyPhotoFormChecks = []
        break;
        //戸建て造成中の場合
        case 'construction':
          for(let i = 0;i < this.constructionPhotoForms.length;i++) {
            for(let p = 0;p < this.constructionPhotoForms[i][this.estateIDs[i]].length;p++) {
              for(let t = 0;t < this.copyConstructionChecks.length;t++) {
                if(this.constructionPhotoForms[i][this.estateIDs[i]][p].estateID == this.copyConstructionChecks[t].estateID) {
                  this.constructionPhotoForms[i][this.estateIDs[i]][p].uploadFile = copyDatas[i][this.estateIDs[i]][t].uploadFile
                  this.constructionPhotoForms[i][this.estateIDs[i]][p].image = copyDatas[i][this.estateIDs[i]][t].image
                  this.constructionPhotoForms[i][this.estateIDs[i]][p].shootDate = copyDatas[i][this.estateIDs[i]][t].shootDate
                  this.constructionPhotoForms[i][this.estateIDs[i]][p].nextShoot = copyDatas[i][this.estateIDs[i]][t].nextShoot
                  this.constructionPhotoForms[i][this.estateIDs[i]][p].uploadFlag = true
                  this.estateDatas[i][this.constructionPhotoForms[i][this.estateIDs[i]][p].estateID].process = estateData.process
                  this.estateDatas[i][this.constructionPhotoForms[i][this.estateIDs[i]][p].estateID].constructionflag = true
                  this.estateDatas[i][this.constructionPhotoForms[i][this.estateIDs[i]][p].estateID].constructionRequiredFlag = true
                }
              }
            }
          }
          this.checkCopyFlag('construction')
        break;
        //マンションの場合
        case 'mansion':
          console.log(copyDatas)
          console.log(estateData)
          for(let copyData of copyDatas) {
            if(copyData[0].shotPlace == shotPlace) {
              console.log(copyData)
            }
          }
        break;
      }
    },

    checkCopyFlag(formType) {
      if(formType != 'construction') {
        // let estateName = []
        for(let t = 0;t < this.estateCheckBox.length;t++) {
          if(this.photoForms[t][this.estateIDs[t]][t].uploadFlag) {
            // this.estateCheckBox[t].checkFlag = true
            // this.copyPhotoFormChecks = []

            // if(this.estateDatas[t][this.photoForms[t][this.estateIDs[t]][t].estateID].estateID == this.photoForms[t][this.estateIDs[t]][t].estateID) {
            //   estateName.push({estateName:this.photoForms[t][this.estateIDs[t]][t].estateName,estateID:this.photoForms[t][this.estateIDs[t]][t].estateID})
            // }
          } else {
            // this.estateCheckBox[t].checkFlag = false
          }
        }
        // console.log(estateName)
        // if(estateName !== []) {
        //   for(let i = 0;i < this.estateDatas.length;i++) {
        //     for(let t = 0; t < estateName.length;t++) {
        //       if(estateName[t].estateID == this.estateDatas[i][this.estateIDs[i]].estateID) {
        //         this.estateDatas[i][this.estateIDs[i]].copy=(estateName)
        //       }
        //     }
        //   }
        // }else {
        //   for(let i = 0;i < this.estateDatas.length;i++) {
        //     for(let t = 0; t < estateName.length;t++) {
        //       if(estateName[t].estateID == this.estateDatas[i][this.estateIDs[i]].estateID) {
        //         this.estateDatas[i][this.estateIDs[i]].copy=[]
        //       }
        //     }
        //   }
        // }
      } else {
        for(let t = 0;t < this.estateCheckBox.length;t++) {
          if(this.constructionPhotoForms[t][this.estateIDs[t]][0].uploadFlag) {
            this.estateCheckBox[t].constructionCheckFlag = true
            this.copyConstructionChecks = []
          } else {
            this.estateCheckBox[t].constructionCheckFlag = false
          }
        }
      }
    },

    // 画像の削除
    deleteImage(formID,estateID,formType,shotPlace) {
      this.$swal({
        title: '写真削除',
        html: `写真を削除しますか？`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '確定',
        cancelButtonText:'キャンセル'
      })
      .then(result => {
        if(result.isConfirmed) {
          // let result = true
          switch(formType) {
            // 戸建ての場合
            case 'exterior':
              for(let i = 0; i < this.photoForms.length;i++) {
                if(Object.keys(this.photoForms[i])[0] == estateID) {
                  for(let t = 0; t < this.photoForms[i][estateID].length;t++) {
                    if(this.photoForms[i][estateID][t].formID == formID) {
                      this.photoForms[i][estateID][t].shootDate = '',
                      this.photoForms[i][estateID][t].image = '',
                      this.photoForms[i][estateID][t].uploadFile =[]
                      this.photoForms[i][estateID][t].uploadFlag = false
                      this.photoForms[i][estateID][t].existingImage = ''
                      this.photoForms[i][estateID][t].photoID = ''
                      if(this.photoForms[i][estateID][t].formID == this.photoForms[i][estateID][t].formID) {
                        this.estateDatas[i][this.photoForms[i][this.estateIDs[i]][t].estateID].copy = []
                      }
                    }
                    if(this.photoForms[i][estateID][t].uploadFlag) {
                      result = false
                    }
                  }
                }
              }
              // if(result) {
              //   for(let estateDatas of this.estateDatas) {
              //     if(Object.keys(estateDatas)[0] == estateID) {
              //       estateDatas[estateID].nextShoot = ''
              //     }
              //   }
              // }
              this.checkCopyFlag()
              break;
            // 戸建て造成中の場合
            case 'construction':
              for(let constructionPhotoForm of this.constructionPhotoForms) {
                if(Object.keys(constructionPhotoForm)[0] == estateID) {
                  constructionPhotoForm[estateID][0].shootDate = '',
                  // constructionPhotoForm[estateID][0].nextShoot = '',
                  constructionPhotoForm[estateID][0].image = '',
                  constructionPhotoForm[estateID][0].uploadFile =[]
                  constructionPhotoForm[estateID][0].uploadFlag = false
                  constructionPhotoForm[estateID][0].existingImage = ''
                  constructionPhotoForm[estateID][0].photoID = ''
                }
              }

              // for(let estateDatas of this.estateDatas) {
              //   if(Object.keys(estateDatas)[0] == estateID) {
              //     estateDatas[estateID].nextShoot = ''
              //   }
              // }

              this.checkCopyFlag('construction')
            break;
            // マンションの場合
            case 'mansion':
              for(let i = 0; i < this.mansionPhotoForms.length;i++) {
                if(Object.keys(this.mansionPhotoForms[i])[0] == estateID) {
                  for(let t = 0; t < this.mansionPhotoForms[i][estateID].length;t++) {
                    for(let mansionPhotoForm of this.mansionPhotoForms[i][estateID][t] ){
                      if(mansionPhotoForm.formID == formID) {
                        mansionPhotoForm.shootDate = '',
                        mansionPhotoForm.image = '',
                        mansionPhotoForm.uploadFile =[]
                        mansionPhotoForm.resizeUploadFile=[]
                        mansionPhotoForm.uploadFlag = false
                        mansionPhotoForm.existingImage = ''
                        mansionPhotoForm.photoID = ''
                        // if(this.photoForms[i][estateID][2].formID == this.photoForms[i][estateID][t].formID) {
                        //   this.estateDatas[i][this.photoForms[i][this.estateIDs[i]][2].estateID].copy = []
                        // }
                      }
                      if(mansionPhotoForm.shotPlace == shotPlace) {
                        if(mansionPhotoForm.uploadFlag) {
                          result = false
                        }
                      }
                    }
                  }
                }
              }
              // if(result) {
              //   for(let i = 0; i < this.mansionPhotoForms.length;i++) {
              //   if(Object.keys(this.mansionPhotoForms[i])[0] == estateID) {
              //     for(let t = 0; t < this.mansionPhotoForms[i][estateID].length;t++) {
              //       for(let mansionPhotoForm of this.mansionPhotoForms[i][estateID][t] ){
              //         if(mansionPhotoForm.formID == formID) {
              //             mansionPhotoForm.nextShoot = ''
              //           }
              //         }
              //       }
              //     }
              //   }
              // }
              // this.checkCopyFlag()
            break;
          }
        }
      })
    },

    // 画像をドラッグオーバー時
    onDrag(type,formID,estateID,formType) {
      switch(formType) {
        // 戸建ての場合
        case 'exterior':
          for(let photoForm of this.photoForms) {
            if(Object.keys(photoForm)[0] == estateID) {
              for(let t = 0; t < photoForm[estateID].length;t++) {
                if(photoForm[estateID][t].formID == formID) {
                  if(!photoForm[estateID][t].uploadFlag) {
                    photoForm[estateID][t].isDragOver = type === "over";
                  }
                }
              }
            }
          }
        break;
        // 戸建て造成中の場合
        case 'construction':
          for(let constructionPhotoForm of this.constructionPhotoForms) {
            if(Object.keys(constructionPhotoForm)[0] == estateID) {
              if(!constructionPhotoForm[estateID][0].uploadFlag){
                constructionPhotoForm[estateID][0].isDragOver = type === "over";
              }
            }
          }
        break;
        // マンションの場合
        case 'mansion':
          for(let mansionPhotoForms of this.mansionPhotoForms) {
            if(Object.keys(mansionPhotoForms)[0] == estateID) {
              for(let t = 0; t < mansionPhotoForms[estateID].length;t++) {
                for(let mansionPhotoForm of mansionPhotoForms[estateID][t]) {
                  if(mansionPhotoForm.formID == formID) {
                    if(!mansionPhotoForm.uploadFlag) {
                      mansionPhotoForm.isDragOver = type === "over";
                    }
                  }
                }
              }
            }
          }
        break;
      }
    },

    // 規定枚数に達しているかチェック、規定枚数になったらプラスボタン
    checkForm(estateID,formType,shotPlace) {
      let result = true
      switch(formType) {
        // 土地・戸建ての場合
        case 'exterior':
          for(let i = 0;i < this.photoForms.length;i++) {
            if(Object.keys(this.photoForms[i])[0] == estateID) {
              for(let t = 0;t < this.photoForms[i][estateID].length;t++) {
                if(!this.photoForms[i][estateID][t].uploadFlag) {
                  result =false
                  break;
                }
              }
              if(result) {
                this.estateDatas[i][estateID].addPhotoFormFlag = true
                this.estateDatas[i][estateID].requiredFormFlag = true
              }
            }
          }
        break;
        // 土地・戸建て造成中の場合
        case 'construction':
          for(let i = 0;i < this.constructionPhotoForms.length;i++) {
            if(Object.keys(this.constructionPhotoForms[i])[0] == estateID) {
              if(!this.constructionPhotoForms[i][estateID][0].uploadFlag) {
                result =false
                break;
              }
              if(result) {
                this.estateDatas[i][estateID].constructionRequiredFlag = true
                // this.estateDatas[i][estateID].requiredFormFlag = true
              }
            }
          }
        break;
        // マンションの場合
        case 'mansion':
          for(let i = 0;i < this.mansionPhotoForms.length;i++) {
            if(Object.keys(this.mansionPhotoForms[i])[0] == estateID) {
              for(let t = 0;t < this.mansionPhotoForms[i][estateID].length;t++) {
                for(let mansionPhotoForm of this.mansionPhotoForms[i][estateID][t]) {
                  if(mansionPhotoForm.shotPlace == shotPlace) {
                    if(!mansionPhotoForm.uploadFlag) {
                      result =false
                      break;
                    }
                  }
                }
              }
            }
          }

          if(result) {
            let requiredFlag = true
            for(let estateDatas of this.estateDatas) {
              if(Object.keys(estateDatas)[0] == estateID) {
                for(let mansionPlace of estateDatas[estateID].mansion) {
                  if(mansionPlace.shotPlace == shotPlace) {
                    mansionPlace.requiredFormFlag = true
                  }
                  if(!mansionPlace.requiredFormFlag) {
                    requiredFlag = false
                  }
                }
              }
            }
            if(requiredFlag) {
              for(let estateDatas of this.estateDatas) {
                if(Object.keys(estateDatas)[0] == estateID) {
                  estateDatas[estateID].requiredFormFlag = true
                }
              }
            }
          }
        break;
      }
    },

    // 戸建て写真リネーム
    renameHousePhoto(photoForms,estateData,estateID,renameDate) {
      this.sendPhotoDatas = []
      for(let photoForm of photoForms) {
        if(photoForm.uploadFlag) {
          this.sendPhotoDatas.push(photoForm)
        }
      }
      estateData['estateType'] = 'property/'
      switch(estateData.process) {
        // 変化なしの場合
        case 1:
          console.log('変化なし')
          this.sendPhotoDatas = []
        break;
        // 更地（再撮影）の場合
        case 9:
          for(let i = 0;i < this.sendPhotoDatas.length;i++) {
            if(this.sendPhotoDatas[i].uploadFlag) {
              this.sendPhotoDatas[i].orderNo = i + 1
            }
          }
          for(let renameMaster of this.houseRenameMaster) {
            if(renameMaster.processID == 3) {
              for(let photoForm of this.sendPhotoDatas) {
                if(photoForm.uploadFlag) {
                  if(renameMaster.orderNo == photoForm.orderNo) {
                    photoForm.uploadFileName = [
                      estateID,
                      renameMaster.processNo
                    ].join('_') + '.jpg'
                  }
                  if(photoForm.uploadFileName == '') {
                    photoForm.uploadFileName = [
                      this.shootingType,
                      estateData.locationID,
                      estateID,
                      estateData.estateName,
                      estateData.fixNumber,
                      renameDate,
                      photoForm.orderNo
                    ].join('_') + '.jpg'
                  }
                }
              }
            }
          }
        break;
        // 完成（再撮影）の場合
        case 10:
          for(let i = 0;i < this.sendPhotoDatas.length;i++) {
            this.sendPhotoDatas[i].orderNo = i + 1
          }
          for(let renameMaster of this.houseRenameMaster) {
            if(renameMaster.processID == 8) {
              for(let photoForm of this.sendPhotoDatas) {
                if(renameMaster.orderNo == photoForm.orderNo) {
                  photoForm.uploadFileName = [
                    estateID,
                    renameMaster.processNo
                  ].join('_') + '.jpg'
                }
                // 拡張分のリネーム
                if(photoForm.uploadFileName == '') {
                  photoForm.uploadFileName = [
                    this.shootingType,
                    estateData.locationID,
                    estateID,
                    estateData.estateName,
                    estateData.fixNumber,
                    renameDate,
                    photoForm.orderNo
                  ].join('_') + '.jpg'
                }
              }
            }
          }
        break;
        // 変化なし以外の場合
        default:
          for(let i = 0;i < this.sendPhotoDatas.length;i++) {
            if(this.sendPhotoDatas[i].uploadFlag) {
              this.sendPhotoDatas[i].orderNo = i + 1
            }
          }
          for(let renameMaster of this.houseRenameMaster) {
            if(renameMaster.processID == estateData.process) {
              for(let photoForm of this.sendPhotoDatas) {
                if(photoForm.uploadFlag) {
                  if(renameMaster.orderNo == photoForm.orderNo) {
                    photoForm.uploadFileName = [
                      estateID,
                      renameMaster.processNo
                    ].join('_') + '.jpg'
                  }
                  // 拡張分のリネーム
                  if(photoForm.uploadFileName == '') {
                    photoForm.uploadFileName = [
                      this.shootingType,
                      estateData.locationID,
                      estateID,
                      estateData.estateName,
                      estateData.fixNumber,
                      renameDate,
                      photoForm.orderNo
                    ].join('_') + '.jpg'
                  }
                }
              }
            }
          }

        break;
      }
      console.log(this.sendPhotoDatas)
    },
    // 土地リネーム
    renameLandPhoto(photoForms,estateData,estateID,renameDate) {
      this.sendPhotoDatas = []
      for(let photoForm of photoForms) {
        if(photoForm.uploadFlag) {
          this.sendPhotoDatas.push(photoForm)
        }
      }
      estateData['estateType'] = 'property/'
      switch(estateData.process) {
        // 変化なしの場合
        case 1:
          console.log('変化なし')
          this.sendPhotoDatas = []
        break;
        // 更地（再撮影）の場合
        case 9:
          for(let i = 0;i < this.sendPhotoDatas.length;i++) {
            this.sendPhotoDatas[i].orderNo = i + 1
          }
          for(let renameMaster of this.landRenameMaster) {
            if(renameMaster.processID == 3) {
              for(let photoForm of this.sendPhotoDatas) {
                if(renameMaster.orderNo == photoForm.orderNo) {
                  photoForm.uploadFileName = [
                    estateID,
                    renameMaster.processNo
                  ].join('_') + '.jpg'
                }
                // 拡張分のリネーム
                if(photoForm.uploadFileName == '') {
                  photoForm.uploadFileName = [
                    this.shootingType,
                    estateData.locationID,
                    estateID,
                    estateData.estateName,
                    estateData.fixNumber,
                    renameDate,
                    photoForm.orderNo
                  ].join('_') + '.jpg'
                }
              }
            }
          }
        break;
        // 変化なし以外の場合
        default:
          for(let i = 0;i < this.sendPhotoDatas.length;i++) {
            this.sendPhotoDatas[i].orderNo = i + 1
          }
          for(let renameMaster of this.landRenameMaster) {
            if(renameMaster.processID == estateData.process) {
              for(let photoForm of this.sendPhotoDatas) {
                if(renameMaster.orderNo == photoForm.orderNo) {
                  photoForm.uploadFileName = [
                    estateID,
                    renameMaster.processNo
                  ].join('_') + '.jpg'
                }
                // 拡張分のリネーム
                if(photoForm.uploadFileName == '') {
                  photoForm.uploadFileName = [
                    this.shootingType,
                    estateData.locationID,
                    estateID,
                    estateData.estateName,
                    estateData.fixNumber,
                    renameDate,
                    photoForm.orderNo
                  ].join('_') + '.jpg'
                }
              }
            }
          }
        break;
      }
      console.log(this.sendPhotoDatas)
    },
    // マンションリネーム
    renameMansionPhoto(photoForms,estateID,estateData,renameDate) {
      estateData['estateType'] = 'mansion/'
      estateData['isMansion'] = true
      this.sendPhotoDatas = []
      for(let photoForm of photoForms) {
        for(let i = 0;i < photoForm.length;i++) {
          if(!photoForm[i].uploadFlag) {
            photoForm.splice(photoForm.indexOf(photoForm[i]),1)
          }
        }
      }
      this.sendPhotoDatas = photoForms
      for(let photoForm of this.sendPhotoDatas) {
        for(let i = 0;i < photoForm.length;i++) {
          photoForm[i].orderNo = i + 1
        }
      }
      for(let renameMaster of this.mansionRenameMaster) {
        for(let photoForm of this.sendPhotoDatas) {
          for(let i = 0;i < photoForm.length;i++) {
            if(photoForm[i].process == renameMaster.processID &&
              renameMaster.orderNo == photoForm[i].orderNo)
            {
              photoForm[i].uploadFileName = [
                estateID,
                renameMaster.processNo
              ].join('_') + '.jpg'
            }
            // 拡張分のリネーム
            if(photoForm[i].uploadFileName == '') {
              photoForm[i].uploadFileName = [
                this.shootingType,
                estateData.locationID,
                estateID,
                estateData.estateName,
                estateData.fixNumber,
                photoForm[i].process,
                renameDate,
                photoForm[i].orderNo
              ].join('_') + '.jpg'
            }
          }
        }
      }
      console.log(this.sendPhotoDatas)
    },

    // 写真登録モーダル
    postPropertyPhotoModal(estateName,estateData) {
      if(estateData.process == '') {
        this.$swal({
          icon: 'error',
          title:'工程を選択してください。',
          confirmButtonText:"閉じる"
        })
        return
      } else if(estateData.typeCode == 1 && estateData.process != 9
        && estateData.process != 1 && estateData.process != 2
        && estateData.process != 3) {
          this.$swal({
          icon: 'error',
          title:'工程を選択してください。',
          confirmButtonText:"閉じる"
        })
        return
      } else if(estateData.nextShoot == '') {
        this.$swal({
          icon: 'error',
          title:'次回撮影予定日を入力してください。',
          confirmButtonText:"閉じる"
        })
        return
      } else {
        this.$swal({
          title: '写真登録',
          html: `「${estateName}」の写真を登録しますか？`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '確定',
          cancelButtonText:'キャンセル'
        })
        .then((result) => {
            if (result.isConfirmed) {
              this.$swal({
                title: '処理中',
                html: `「${estateName}」の写真を登録中です。`,
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
              });
              this.isButtonDisabled = true;
              try {
                this.postPhoto(estateData);
                this.isButtonDisabled = false;
              } catch (error) {
                this.isButtonDisabled = false;
                console.error('写真の登録中にエラーが発生しました:', error);
              }
            }
        })
      }

    },
    // 外観写真登録
    async postPhoto(estateData) {
      console.log(estateData)
      let renameDate = moment().format("YYYYMMDDHHmmss")
      // 物件タイプの判定
      switch(estateData.typeCode) {
        // 戸建ての場合
        case 2:
          switch(estateData.process) {
            // 造成中の場合
            case 2:
              for(let photoForms of this.constructionPhotoForms) {
                if(Object.keys(photoForms)[0] == estateData.estateID) {
                  // 新規登録の場合
                  if(!this.registeredPhotoFlag) {
                    if(!photoForms[estateData.estateID][0].uploadFlag) {
                      this.$swal({
                        icon: 'error',
                        title:'規定枚数に足りてません。',
                        confirmButtonText:"閉じる"
                      })
                      return
                    } else {
                      await this.renameHousePhoto(
                        photoForms[estateData.estateID],
                        estateData,
                        estateData.estateID,
                        renameDate
                      )
                    }
                  // 更新で0枚の場合
                  } else if(!photoForms[estateData.estateID][0].uploadFlag){
                    this.sendPhotoDatas = []
                  // 更新の場合
                  } else {
                    await this.renameHousePhoto(
                      photoForms[estateData.estateID],
                      estateData,
                      estateData.estateID,
                      renameDate
                    )
                  }
                }
              }
            break;
            // 変化なしだった場合
            case 1:
              for(let photoForms of this.photoForms) {
                await this.renameHousePhoto(
                  photoForms[estateData.estateID],
                  estateData,
                  estateData.estateID,
                  renameDate
                )
              }
            break;
            // 造成中・変化なし以外の場合
            default:
              for(let photoForms of this.photoForms) {
                if(Object.keys(photoForms)[0] == estateData.estateID) {
                let uploadCount = 0
                  for(let photoForm of photoForms[estateData.estateID]) {
                    if(photoForm.uploadFlag) {
                      uploadCount ++
                    }
                  }
                  // 新規登録の場合
                  if(!this.registeredPhotoFlag) {
                    if(uploadCount >= this.requiredNumber) {
                      await this.renameHousePhoto(
                        photoForms[estateData.estateID],
                        estateData,
                        estateData.estateID,
                        renameDate
                      )
                    } else{
                      this.$swal({
                        icon: 'error',
                        title:'規定枚数に足りてません。',
                        confirmButtonText:"閉じる"
                      })
                      return
                    }
                  // 更新で０枚の場合
                  } else if(uploadCount == 0) {
                    this.sendPhotoDatas = []
                    // 更新で0枚以上規定枚数以下の場合
                  } else if(uploadCount >= this.requiredNumber) {
                    await this.renameHousePhoto(
                      photoForms[estateData.estateID],
                      estateData,
                      estateData.estateID,
                      renameDate
                    )
                    // 規定枚数以下の場合
                  } else {
                    this.$swal({
                      icon: 'error',
                      title:'規定枚数に足りてません。',
                      confirmButtonText:"閉じる"
                    })
                    return
                  }
                }
              }
            break;
          }
        break;
        // 土地の場合
        case 1:
          switch(estateData.process) {
            // 造成中の場合
            case 2:
              for(let photoForms of this.constructionPhotoForms) {
                if(Object.keys(photoForms)[0] == estateData.estateID) {
                  // 新規登録の場合
                  if(!this.registeredPhotoFlag) {
                    if(!photoForms[estateData.estateID][0].uploadFlag) {
                      this.$swal({
                        icon: 'error',
                        title:'規定枚数に足りてません。',
                        confirmButtonText:"閉じる"
                      })
                      return
                    } else {
                      await this.renameLandPhoto(
                        photoForms[estateData.estateID],
                        estateData,
                        estateData.estateID,
                        renameDate
                      )
                    }
                  // 更新で0枚の場合
                  } else if(!photoForms[estateData.estateID][0].uploadFlag){
                    this.sendPhotoDatas = []
                  } else {
                    await this.renameHousePhoto(
                      photoForms[estateData.estateID],
                      estateData,
                      estateData.estateID,
                      renameDate
                    )
                  }
                }
              }
            break;
            // 変化なしの場合
            case 1:
              for(let photoForms of this.photoForms) {
                await this.renameHousePhoto(
                  photoForms[estateData.estateID],
                  estateData,
                  estateData.estateID,
                  renameDate
                )
              }
            break;
            // 造成中・変化なし以外の場合
            default:
              for(let photoForms of this.photoForms) {
                if(Object.keys(photoForms)[0] == estateData.estateID) {
                  let uploadCount = 0
                  for(let photoForm of photoForms[estateData.estateID]) {
                    if(photoForm.uploadFlag) {
                      uploadCount ++
                    }
                  }
                  // 新規登録の場合
                  if(!this.registeredPhotoFlag) {
                    if(uploadCount >= this.requiredNumber) {
                      await this.renameLandPhoto(
                          photoForms[estateData.estateID],
                          estateData,
                          estateData.estateID,
                          renameDate
                        )
                    } else {
                      this.$swal({
                        icon: 'error',
                        title:'規定枚数に足りてません。',
                        confirmButtonText:"閉じる"
                      })
                      return
                    }
                  // 更新で枚数0の場合
                  } else if(uploadCount == 0) {
                    this.sendPhotoDatas = []
                  // 更新で枚数0枚以上規定枚数以下の場合
                  } else if(uploadCount >= this.requiredNumber) {
                    await this.renameLandPhoto(
                      photoForms[estateData.estateID],
                      estateData,
                      estateData.estateID,
                      renameDate
                    )
                  // 更新で規定枚数に足りていない場合
                  }else {
                    this.$swal({
                      icon: 'error',
                      title:'規定枚数に足りてません。',
                      confirmButtonText:"閉じる"
                    })
                    return
                  }
                }
              }
            break;
          }
        break;
        // マンションの場合
        case 3:
          switch(estateData.process) {
            // 変化なしの場合
            case 1:
              for(let mansionPhotoForms of this.mansionPhotoForms) {
                await this.renameMansionPhoto(
                  mansionPhotoForms[estateData.estateID],
                  estateData.estateID,
                  estateData,
                  renameDate
                )
              }
            break;
            // 変化なし以外の場合
            default:
              for(let mansionPhotoForms of this.mansionPhotoForms) {
                if(Object.keys(mansionPhotoForms)[0] == estateData.estateID) {
                  let uploadExteriorCount = 0
                  let uploadEntranceCount = 0
                  for(let photoForms of mansionPhotoForms[estateData.estateID]) {
                    for(let photoForm of photoForms) {
                      if(photoForm.process == 1) {
                        if(photoForm.uploadFlag) {
                          uploadExteriorCount ++
                        }
                      }
                      if(photoForm.process == 2) {
                        if(photoForm.uploadFlag) {
                          uploadEntranceCount ++
                        }
                      }
                    }
                  }
                  // 新規登録の場合
                  if(!this.registeredPhotoFlag) {
                    if(uploadExteriorCount >= this.mansionExteriorRequiredNumber &&
                    uploadEntranceCount >= this.mansionEntrenceRequiredNumber) {
                        await this.renameMansionPhoto(
                          mansionPhotoForms[estateData.estateID],
                          estateData.estateID,
                          estateData,
                          renameDate
                        )
                    } else {
                      this.$swal({
                        icon: 'error',
                        title:'規定枚数に足りてません。',
                        confirmButtonText:"閉じる"
                      })
                      return
                    }
                  // 更新で撮影枚数0の場合
                  } else if(
                    this.registeredPhotoFlag &&
                    uploadExteriorCount == 0 &&
                    uploadEntranceCount == 0) {
                    this.sendPhotoDatas = []
                  // 更新で撮影枚数0枚以上規定枚数以下の場合
                  } else if(
                    uploadExteriorCount >= this.mansionExteriorRequiredNumber &&
                    uploadEntranceCount >= this.mansionEntrenceRequiredNumber) {
                      await this.renameMansionPhoto(
                        mansionPhotoForms[estateData.estateID],
                        estateData.estateID,
                        estateData,
                        renameDate
                      )
                    // 更新で規定枚数以下の場合
                    } else {
                    this.$swal({
                      icon: 'error',
                      title:'規定枚数に足りてません。',
                      confirmButtonText:"閉じる"
                    })
                    return
                  }
                }
              }
          }
        break;
      }
      console.log("postPhoto: " + this.sendPhotoDatas)
      await this.postExteriorPhoto(
        this.sendPhotoDatas,
        estateData.estateID,
        this.userID,
        estateData,
        estateData.typeCode,
        this.s3,
        this.s3Bucket
      )

    }
  },
  watch:{
    'locationDatas':function() {
      switch(this.shootType) {
        // 土地の場合
        case 1:
          this.landFlag = true
          this.estateKindType = 'land'
          this.createHouseForms()
          this.showLoader = false
        break;
        // 戸建ての場合
        case 2:
          this.houseFlag = true
          this.estateKindType = 'house'
          this.createHouseForms()
          this.showLoader = false
        break;
        // マンションの場合
        case 3:
          this.mansionFlag = true
          this.estateKindType = 'mansion'
          for(let i = 0;i < this.locationDatas.length;i++) {
            this.mansionPhotoForms.push({[this.locationDatas[i].cEstateID]:[]})
          }
          this.createMansionForms()
          this.showLoader = false
        break;
      }
    },
    'registeredPhotoFlag':function() {
      if(this.registeredPhotoFlag) {
        this.getRegisteredPhoto()
        this.changeProcess(Object.keys(this.estateDatas[0])[0])
      }
    },
  }
}

</script>

<style scoped>

.custom-file-drop .custom-file-drop-input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
}
.custom-file-drop {
    position: relative;
}
.custom-file-drop .custom-file-drop-label {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0.375rem 0.5rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    background-color: rgba(255, 255, 255, 0.95);
    color: #909ead;
    cursor: pointer;
}

.formLabel {
  display: inline-block;
  width: 140px;
  text-align: right;
}

.image-input {
  background-color: #eee;
  width: 100%;
  height: 300px;

}
.image-input__field {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-input__field.over {
  background-color: #666;
}
.image-input__field > input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.image-input__field > p {
  color: #aaa;
  text-align: center;
}

.add_btn:hover {
  opacity: 0.5;
}

  .ddSort {
    display: inline-block;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    background-color: #ffffff;
  }
  .ddSort:hover {
    cursor: grab;
  }
  .ddSort:active {
    cursor: grabbing;
  }

.sidebar-area {
  float: left;
  border-right: 1px solid;
}

.property_info {
  position: sticky;
  top:58px;
  background-color: white;
  z-index:100;
}


.scafold-wrapper {
  flex-direction: column;
  min-height: 100vh;
  width: calc(100% - 250px);
  margin: 0 0 0 250px;
}

</style>
